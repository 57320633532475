import { DISPLAY_DATE_YEAR_TIME_FORMAT } from "constants/dateTime";
import moment from "moment";

export const getTableConfig = () => [
  {
    field: "report_type",
    headerName: "Report Type",
    sortable: false,
    valueFormatter: (record) => {
      return (
        report_sections.find(
          (item) => item?.request_type === record?.requested_report_type
        )?.name || "-"
      );
    },
    width: "200px",
    columnClassName: "word_break",
    ellipsis: true,
    maxCharCount: 25,
  },
  {
    field: "request_date",
    headerName: "Request Date",
    valueFormatter: (record) =>
      moment(record.created_at).format(DISPLAY_DATE_YEAR_TIME_FORMAT),
    sortable: false,
    noWrap: true,
  },
  {
    field: "date_range",
    headerName: "Date Range",
    valueFormatter: (record) => {
      const startDate = record.report_start_date;
      const endDate = record.report_end_date;
      const isTrafficAnalysisReport =
        record.report_type === REPORT_TYPES.TRAFFIC_ANALYSIS;
      let dateStringToDisplay = "N/A";
      if (!isTrafficAnalysisReport && startDate) {
        dateStringToDisplay = moment(startDate).utc().format("MMMM DD, YYYY");
        if (endDate)
          dateStringToDisplay += ` - ${moment(endDate)
            .utc()
            .format("MMMM DD, YYYY")}`;
      }
      return dateStringToDisplay;
    },
    sortable: false,
    ellipsis: true,
    maxCharCount: 60,
    noWrap: true,
  },
];

export const form_field_labels = {
  section: "Section",
  start_date: "Start date",
  end_date: "End date",
  report_type: "Report type",
  request_date: "Request date",
  date_range: "Date range",
  download: "Download",
  title: "Generate Report",
};

export const REPORT_VALUES = {
  TRANSACTIONS: 0,
  ALL_LEADS: 1,
  USER_PAYMENT: 2,
  PAYMENTS: 3,
  TRAFFIC_ANALYSIS: 4,
};

export const REPORT_TYPES = {
  TRANSACTIONS: 1,
  ALL_LEADS: 2,
  MIS_REPORT: 3,
  PAYOUTS: 4,
  TRAFFIC_ANALYSIS: 5,
  CUSTOMERS: 6,
  SUBSCRIPTIONS: 7,
  BOOKINGS: 8,
  REFUND_HISTORY: 9,
};

export const REPORT_SOURCE = {
  CREATOR_TOOL_REPORTS: 2,
  WORKFLOW_REPORTS: 3,
};

export const report_sections = [
  {
    name: "Transactions",
    id: 0,
    label: "Transactions",
    value: REPORT_VALUES.TRANSACTIONS,
    hasDateFilters: true,
    request_type: REPORT_TYPES.TRANSACTIONS,
  },
  {
    name: "All Leads",
    id: 1,
    label: "All Leads",
    value: REPORT_VALUES.ALL_LEADS,
    hasDateFilters: true,
    request_type: REPORT_TYPES.ALL_LEADS,
  },
  {
    name: "User Payments - MIS",
    id: 2,
    label: "User Payments - MIS",
    value: REPORT_VALUES.USER_PAYMENT,
    hasDateFilters: true,
    request_type: REPORT_TYPES.MIS_REPORT,
  },
  {
    name: "Payouts",
    id: 3,
    label: "Payouts",
    value: REPORT_VALUES.PAYMENTS,
    hasDateFilters: true,
    request_type: REPORT_TYPES.PAYOUTS,
  },
  {
    name: "Traffic Analysis",
    id: 4,
    label: "Traffic Analysis",
    value: REPORT_VALUES.TRAFFIC_ANALYSIS,
    request_type: REPORT_TYPES.TRAFFIC_ANALYSIS,
  },
];

export const REPORT_STATUS = {
  PENDING: 1,
  PROCESSING: 2,
  COMPLETED: 3,
  FAILED: 4,
};

export const getRecordStatusValues = (record = {}) => {
  const { response } = record;

  const values = {
    [REPORT_STATUS.PENDING]: {
      color: "warning",
      tooltip: "Your report will be mailed to you within 1 hour.",
      label: "Pending",
    },
    [REPORT_STATUS.PROCESSING]: {
      color: "warning",
      tooltip: "Your report will be mailed to you within 1 hour.",
      label: "Processing",
    },
    [REPORT_STATUS.COMPLETED]: {},
    [REPORT_STATUS.FAILED]: {
      color: "danger",
      tooltip: response || "Failed to generate Report, Please try again",
      label: "Failed",
    },
  };

  return values[record.status] || {};
};

export const REPORTS_LEARN_MORE_HREFS = {
  REPORTS:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392249-generate-reports-on-exly",
};

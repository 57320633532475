import {
  SALES_PAGES__UPDATE__FETCH_SECTION_DETAIL,
  SALES_PAGES__UPDATE__FETCH_TEMPLATE_DETAIL,
  SALES_PAGES__UPDATE__SET_SALE_PAGE_DETAILS,
  SALES_PAGES__UPDATE__SET_SECTION_DETAIL,
  SALES_PAGES__UPDATE__SET_SELECTED_TAB,
  SALES_PAGES__UPDATE__SET_TEMPLATE_DETAIL,
} from "./actions";
import { put, takeEvery, call, select } from "redux-saga/effects";
import dataProvider from "data/dataProvider";
import { sales_pages_apis } from "../API";
/* eslint-disable no-unused-vars */
import {
  ISalesPagesReducer,
  SectionInterface,
  SelectedTemplateInterface,
} from "../types";
/* eslint-disable no-unused-vars */
import { is_empty } from "utils/validations";
import { sale_page_preview_iframe_id } from "../components/Preview/constants";
import { schedule_types_ids } from "constants/schedule";
import { getSelectedSalesPageUuid } from "../utils";
import api from "data/APIs";
import { apiMethods } from "data/api.constants";
import { sales_page_detail_keys } from "features/SalesPages/constants/SalesPages.constants";
import { isRequestSuccessful } from "utils/Utils";

function* fetchTemplateDetail({
  payload,
  errorCallback,
  force = false,
}: any): any {
  try {
    const templateData: SelectedTemplateInterface = yield select(
      (state) => state.salesPages.update.selectedTemplate
    );
    if (!is_empty(templateData.template_data) && !force) {
      yield put({
        type: SALES_PAGES__UPDATE__SET_TEMPLATE_DETAIL,
        payload: templateData,
      });
    } else {
      const { listingUuid } = yield select(
        (state: { salesPages: ISalesPagesReducer }) => state.salesPages.update
      );
      const response = yield call(
        dataProvider.custom_request,
        `${sales_pages_apis.fetch_template_detail}/${payload.templateUuid}`,
        "GET",
        {
          sales_page_uuid: getSelectedSalesPageUuid(),
        }
      );

      if (response?.status === 200) {
        const template = response.data.template;
        yield put({
          type: SALES_PAGES__UPDATE__SET_TEMPLATE_DETAIL,
          payload: template,
        });
        yield put({
          type: SALES_PAGES__UPDATE__SET_SELECTED_TAB,
          payload: Object.keys(template.tabs)[0],
        });
      } else {
        throw `API: ${sales_pages_apis.fetch_template_detail} FAIL`;
      }
    }
  } catch (error) {
    if (errorCallback) yield call(errorCallback);
  }
}

function* fetchSectionDetail({ payload, errorCallback }: any): any {
  try {
    const { selectedTab, salesPageUuid, sectionUuid } = payload;
    let { selectedTemplate: template, listingData: currentListingData } =
      yield select(
        (state: { salesPages: ISalesPagesReducer }) => state.salesPages.update
      ) || {};
    let sectionData;
    if (template) {
      sectionData = template.tabs[selectedTab].find(
        (item: SectionInterface) => item.uuid === sectionUuid
      );
    }

    if (is_empty(sectionData.section_data)) {
      const response = yield call(
        dataProvider.custom_request,
        `${sales_pages_apis.fetch_section_detail}/${salesPageUuid}`,
        "GET",
        { template_section_uid: sectionUuid }
      );

      if (isRequestSuccessful(response?.status)) {
        const salePageDetailsInApi = response?.data?.sales_page || {};
        const titleInApi = salePageDetailsInApi[sales_page_detail_keys.title];
        const listingUuidInApi =
          salePageDetailsInApi[sales_page_detail_keys.listing_uuid];
        const listingTypeInApi =
          salePageDetailsInApi[sales_page_detail_keys.listing_type];
        if (titleInApi || listingUuidInApi || listingTypeInApi) {
          // if the listing uuid from api is not same as the current listing uuid already in redux,
          if (listingUuidInApi !== currentListingData?.uuid) {
            // then we need to fetch the listing details of the new listing
            const listingResponse = yield call(
              dataProvider.custom_request,
              `${api.get_listing_details}/${listingUuidInApi}`,
              apiMethods.GET,
              { update_schedule: "True" }
            );
            const listingData = listingResponse?.data?.event;
            // update the new listing data in redux
            yield put({
              type: SALES_PAGES__UPDATE__SET_SALE_PAGE_DETAILS,
              payload: {
                title: titleInApi,
                listingUuid: listingUuidInApi,
                listingType: listingTypeInApi,
                listingData,
              },
            });
          } else {
            // else we do not need to fetch any new data and update the current listing data in redux
            // this else block is needed because during the first section data fetch we won't be having this data in redux, also, the above
            // if condition will not work, so we need to set the data in that case
            yield put({
              type: SALES_PAGES__UPDATE__SET_SALE_PAGE_DETAILS,
              payload: {
                title: titleInApi,
                listingUuid: listingUuidInApi,
                listingType: listingTypeInApi,
                listingData: currentListingData,
              },
            });
          }
        }

        const sectionInApi = salePageDetailsInApi.sections?.[0] || {};
        const sectionDataInApi = sectionInApi.data || {};

        // fetch catlogue from listing details for `listingType === offering_types_ids.content`
        let catalogueItemsList;
        const { listingUuid, listingType } = yield select(
          (state: { salesPages: ISalesPagesReducer }) => state.salesPages.update
        );

        if (listingType === schedule_types_ids.content) {
          // Check if section_data exists, if not initialize it
          if (!sectionDataInApi.section_data) {
            sectionDataInApi.section_data = {};
          }
          const recordedContentCurriculum = yield call(
            dataProvider.custom_request,
            sales_pages_apis.fetch_recorded_content_curriculum,
            "GET",
            { listing_uuid: listingUuid }
          );
          catalogueItemsList = (
            recordedContentCurriculum?.data?.catalogue || []
          ).map((i: any) => ({
            id: i.uuid,
            title: i.title,
            catalogueItemList: i.sub_categories.map((j: any) => ({
              id: j.uuid,
              title: j.title,
            })),
          }));
          sectionDataInApi.section_data.catalogueItemsList = catalogueItemsList;
          sectionDataInApi.section_data.isCatalogueItemsListUneditable = true;
        }

        if (sectionDataInApi.section_data) {
          sectionData = sectionDataInApi.section_data;
          sectionData.section_name = sectionInApi.section_name;
          yield put({
            type: SALES_PAGES__UPDATE__SET_SECTION_DETAIL,
            payload: { sectionUuid, sectionData, selectedTab },
          });
        }
      } else {
        throw `API: ${sales_pages_apis.fetch_section_detail} FAIL`;
      }
    }
  } catch (error) {
    if (errorCallback) yield call(errorCallback);
  }
}

function* updateIframeData({ payload }: any): any {
  const { selectedTab, sectionUuid } = payload;
  let template = yield select(
    (state: { salesPages: ISalesPagesReducer }) =>
      state.salesPages.update.selectedTemplate
  );
  let section = template.tabs[selectedTab].find(
    (item: SectionInterface) => item.uuid === sectionUuid
  );

  const pageFrame = document.getElementById(sale_page_preview_iframe_id);
  // @ts-ignore
  pageFrame?.contentWindow?.postMessage?.({ section }, "*");
  yield;
}

function* salesPagesSaga() {
  yield takeEvery(
    SALES_PAGES__UPDATE__FETCH_TEMPLATE_DETAIL,
    fetchTemplateDetail
  );
  yield takeEvery(
    SALES_PAGES__UPDATE__FETCH_SECTION_DETAIL,
    fetchSectionDetail
  );

  yield takeEvery(SALES_PAGES__UPDATE__SET_SECTION_DETAIL, updateIframeData);
}

export default salesPagesSaga;

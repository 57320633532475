export const sales_pages_apis = {
  get_sales_page_templates_list: "marketing/sales/page/templates",
  get_sales_pages_list: "marketing/sales/page/list",
  create_sales_page: "marketing/sales/page/add",

  // NOTE:
  // if: you are changing metadata
  // then: remember to spread older metadata values to preserve it after update
  update_sales_page: "marketing/sales/page/update",

  update_section: "marketing/sales/page/section/create",
  fetch_template_detail: "marketing/sales/page/template/detail",
  fetch_section_detail: "marketing/sales/page/details",
  fetch_recorded_content_curriculum: "host/recorded/curriculum",
  duplicate_sale_page: "marketing/sales/page/duplicate",
  delete_sale_page: "marketing/sales/page/delete",
  publish_sale_page: "marketing/sales/page/publish",
};

import Image from "@material-ui/icons/Image";
import Videocam from "@material-ui/icons/Videocam";
import Subject from "@material-ui/icons/Subject";
import { TOOLBAR_ELEMENT_NAME } from "constants/RTEToolbarGroupsConstants";
import { SalesPageMetaData } from "./types";
import { query_params } from "features/SalesPages/modules/GJSSalePageBuilder/constants/GJSSalePageBuilder.constants";

export const sales_page_stages = {
  template_selecting_stage: 1,
  details_editing_stage: 2,
  published: 3,
};
export const TEMPLATE_UUID_PARAM = query_params.template_uuid;
export const SALES_PAGE_UUID_PARAM = "sales_page";
export const DEFAULT_TEMPLATE_UPDATE_TAB = "Details";

export const BANNER_IMAGE_SLUG = "BannerImage";
export const TESTIMONIALS_SLUG = "Testimonials";
export const CERTIFICATES_SLUG = "Certificates";
export const DEMO_VIDEO_SLUG = "DemonstrationVideo";
export const GALLERY_SLUG = "GallerySection";

export const SECTION_SLUGS = [
  "AboutSection",
  "GuranteeSection",
  "RegisterButton",
  "Highlights",
  "Requirements",
  BANNER_IMAGE_SLUG,
  "CountdownTimer",
  "PromotionalStats",
  "HowToBook",
  "FeaturedIn",
  "WhatYouWillGetToDo",
  "WhatYouWillLearn",
  "WhoIsItFor",
  "ContentCatalogue",
  "BonusSection",
  CERTIFICATES_SLUG,
  TESTIMONIALS_SLUG,
  "FAQs",
  "BackgroundImage",
  "Contact",
  "ColourPalette",
  DEMO_VIDEO_SLUG,
  "CustomPromotionalStats",
  "AboutCreator",
  "CircularBannerImage",
  "MultipleBackgroundImageSection",
  "CoachIntro",
  "Awards",
  "ThisCouldBeYourLife",
  "WhatWillChangeInYourLife",
  "TabAssets",
  "HeroSection",
  "ActionHighlightButton",
  "CallToAction",
  "AboutBrand",
  GALLERY_SLUG,
  "CustomHighlights",
  "ContentOutline",
  "CrossDataShowcase",
  "DemoVideoHighlights",
  "StickyText",
  "MoneybackSection",
  "ContactSection",
  "PainPointsSection",
  "StickyFooterSection",
  "DesiredOutcomes",
];

export const SALES_PAGE_STATUS = {
  LIVE: 1,
  SOLD_OUT: 2,
  EXPIRED: 3,
  DELETED: 5,
};

export const default_template_preview_image =
  "https://exly-public-content.s3.ap-south-1.amazonaws.com/creator-tool/sales-pages/templates/no_template.png";

export const LIST_DESIGN = {
  VERTICAL_LIST: { slug: "vertical_list", label: "Vertical list" },
  HORIZONTAL_CAROUSEL: {
    slug: "horizontal_carousel",
    label: "Horizontal carousel",
  },
};

export const VIEWS_MAP = {
  IMG_VIEW: { slug: "img_view", label: "Img" },
  VIDEO_VIEW: { slug: "video_view", label: "Video" },
};

export const ADD_VIDEO_OPTIONS = {
  ADD_LINK: { slug: "add_link", label: "Via link" },
  UPLOAD_VIDEO: { slug: "upload_video", label: "Upload video" },
  ADD_EMBED_CODE: { slug: "add_embed_code", label: "Embed code" },
};

export const TESTIMONIAL_TYPES_MAP: {
  [key: string]: {
    slug: string;
    label: string;
    icon: any;
  };
} = {
  TEXT: { slug: "text_view", label: "TEXT", icon: Subject },
  IMAGE: { slug: "img_view", label: "IMAGE", icon: Image },
  VIDEO: { slug: "video_view", label: "VIDEO", icon: Videocam },
};

export const SECTION_INPUTS_NAME: { [key: string]: string } = {
  section_name: "section_name",
  section_desc: "section_desc",
  description: "description",
  timerRunningOutText: "timerRunningOutText",
  hurryText: "hurryText",
  title: "title",
  short_description: "short_description",
  overline: "overline",
  date: "date",
  time: "time",
  pointer: "pointer",
  pointer2: "pointer2",
  caption: "caption",
};
export const SECTION_INPUTS_PLACEHOLDER: { [key: string]: string } = {
  section_name: "Enter Section name...",
  section_desc: "Enter Section Description...",
  description: "Write description here...",
  timerRunningOutText: "eg: Time Is running Out. Grab Your Spot Fast!",
  hurryText: "eg: Hurry! Grab the deal today!",
  title: "Enter Title...",
  short_description: "Write description here...",
  price: "Enter price...",
  something: "Write Something here...",
  organization: "Enter name of the organization",
  overline: "Enter overline text ...",
  caption: "Write caption here...",
};
export const SECTION_INPUTS_MODAL_TITLE: { [key: string]: string } = {
  section_name: "What would you like to call this section?*",
  section_desc: "Enter Description",
  description: "Description",
  timerRunningOutText: "What would you like to call this section?",
  hurryText: "Offer text",
  title: "Title",
  short_description: "Description",
  caption: "Caption",
  sectionName: "What would you like to call this section?*",
};

export const SALE_PAGE_SECTION_ERROR_TEXT = {
  required: "Required",
  invalid: "Invalid",
  incorrect_url: "Incorrect URL",
  invalid_url: "Invalid URL",
};

// RTE names remove from toolbar for sales page
export const RTE_SECTIONS_TOOLBAR_ELEMENT_NAME = [
  TOOLBAR_ELEMENT_NAME.font_heading,
  TOOLBAR_ELEMENT_NAME.font_family,
  TOOLBAR_ELEMENT_NAME.alignment,
  TOOLBAR_ELEMENT_NAME.indent_right,
  TOOLBAR_ELEMENT_NAME.indent_left,
  TOOLBAR_ELEMENT_NAME.image,
  TOOLBAR_ELEMENT_NAME.table,
];

export const TEMPLATE_SLUGS = {
  template9: "template9",
  template7: "template7",
  template6: "template6",
  template5: "template5",
  template4: "template4",
  template3: "template3",
  template2: "template2",
  template1: "template1",
  template10: "template10",
  custom_grapesjs_scratch: "custom-grapesjs-scratch",
};

// default values for images for the sales page according to the section slug
export const SALES_PAGE_SECTIONS_IMG_DIM: {
  [key: string]: SalesPageMetaData;
} = {
  FeaturedIn: { width: 150, height: 50 },
  BannerImage: { width: 1280, height: 720 },
  BonusSection: { width: 150, height: 150 },
  AboutCreator: { width: 542, height: 542 },
  CircularBannerImage: { width: 144, height: 144 },
  CustomPromotionalStats: { width: 45, height: 45 },
  Certificates: { width: 640, height: 418 },
  MoneybackSection: { width: 520, height: 370 },
  ContactSection: { width: 368, height: 70 },
  DesiredOutcomes: { width: 20, height: 20 },
  AboutSection: { width: 50, height: 50 },
  GuranteeSection: { width: 476, height: 450 },
  WhatWillChangeInYourLife: { width: 73, height: 73 },
  Testimonials: {
    customerImgDim: {
      height: 150,
      width: 150,
    },
    imgCoverDim: {
      height: 600,
      width: 600,
    },
    videoCoverDim: {
      height: 600,
      width: 600,
    },
  },
};

export const SALE_PAGE_TESTIMONIAL_PREVIEW_IMAGE_DIMESIONS = 250;

export const SALES_PAGE_CONTENT_BUCKET_NAME =
  process.env.REACT_APP_S3_BUCKET_EXLY_CONTENT;

export const DEFAULT_PREVIEW_PAGE_URL_MAP = {
  [TEMPLATE_SLUGS.template1]:
    "https://nomitexly.exlyapp.com/offer/a68089d5-c151-4931-9abe-7d7a4748a0d8",
  [TEMPLATE_SLUGS.template2]:
    "https://nomitexly.exlyapp.com/offer/ce7ab714-7bb4-42b9-a258-bf7a8550312f",
  [TEMPLATE_SLUGS.template3]:
    "https://nomitexly.exlyapp.com/offer/78b31faa-8a68-45ef-8e00-db9b5eef58ed",
  [TEMPLATE_SLUGS.template4]:
    "https://nomitexly.exlyapp.com/offer/3521ff14-9714-432b-b296-f0a52dff6113",
  [TEMPLATE_SLUGS.template5]:
    "https://nomitexly.exlyapp.com/offer/066a0934-40bc-4a2d-96d7-3713840934a2",
  [TEMPLATE_SLUGS.template6]:
    "https://nomitexly.exlyapp.com/offer/450aff53-0d7b-4ea5-9565-f323902bfd79",
  [TEMPLATE_SLUGS.template7]:
    "https://nomitexly.exlyapp.com/offer/dcdf3060-e05d-431a-957c-27e5b8a26941",
  [TEMPLATE_SLUGS.template9]:
    "https://nomitexly.exlyapp.com/offer/ebcb44e1-3e36-4f63-a5d8-04652aa23cf4",
};

export const SECTIONS_USING_VIDEO_UPLOADER = {
  DEMO_VIDEO: DEMO_VIDEO_SLUG,
  TESTIMONIALS: TESTIMONIALS_SLUG,
  BANNER_IMAGE: BANNER_IMAGE_SLUG,
  CERTIFICATES: CERTIFICATES_SLUG,
  GALLERY: GALLERY_SLUG,
};

export const SECTIONS_USING_VIDEO_UPLOADER_SLUG_VALUES = Object.values(
  SECTIONS_USING_VIDEO_UPLOADER
);

export const ADD_FEATURE_VALUES = {
  DEFAULT_SECTION_NAME: "feature",
  CUSTOM_NAME: "benefit",
  DEFAULT_TITLE: "Add feature",
  CUSTOM_TITLE: "Add a benefit",
  DEFAULT_MEDIA_NAME: "Media logo",
  CUSTOM_MEDIA_NAME: "Icon",
  DEFAULT_LABEL: "Name of the organization",
  CUSTOM_LABEL: "Text",
  CUSTOM_PLACEHOLDER: "Enter text here...",
};

export const customNames = {
  [ADD_FEATURE_VALUES.CUSTOM_NAME]: {
    label_name: ADD_FEATURE_VALUES.CUSTOM_LABEL,
    placeholder: ADD_FEATURE_VALUES.CUSTOM_PLACEHOLDER,
    mediaLogoName: ADD_FEATURE_VALUES.CUSTOM_MEDIA_NAME,
    title: ADD_FEATURE_VALUES.CUSTOM_TITLE,
  },
};

export const SALES_PAGE_LEARN_MORE_HREFS = {
  SALES_PAGE:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392247-create-sales-pages-on-exly",
  SALES_PAGE_VIDEO: "https://www.youtube-nocookie.com/embed/YKZ5W0QnFkM",
};

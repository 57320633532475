import React from "react";
import ComputerIcon from "@material-ui/icons/Computer";
import SmartphoneIcon from "@material-ui/icons/Smartphone";

export const tabs_ids = {
  mobile: 0,
  desktop: 1,
};

export const tabs_list = [
  {
    label: <SmartphoneIcon />,
    id: tabs_ids.mobile,
  },
  {
    label: <ComputerIcon />,
    id: tabs_ids.desktop,
  },
];

export const sale_page_preview_iframe_id = "sale_page_preview";

// Update
export const SALES_PAGES__UPDATE_TEMPLATES_LIST =
  "@SALES_PAGES/UPDATE/FETCH_TEMPLATES_LIST";
export const SALES_PAGES__UPDATE_SALES_PAGES_LISTS =
  "@SALES_PAGES/UPDATE/SALES_PAGES_LISTS";
export const SALES_PAGES__UPDATE_ALL_LIVE_SALES_PAGES_LIST =
  "@SALES_PAGES/UPDATE/ALL_LIVE_SALES_PAGES_LIST";
export const SALES_PAGES__UPDATE__FETCH_TEMPLATE_DETAIL =
  "@SALES_PAGES/UPDATE/FETCH_TEMPLATE_DETAIL";
export const SALES_PAGES__UPDATE__SET_SALES_PAGE_DETAIL =
  "@SALES_PAGES/UPDATE/SET_SALES_PAGE_DETAIL";
export const SALES_PAGES__UPDATE__SET_TEMPLATE_DETAIL =
  "@SALES_PAGES/UPDATE/SET_TEMPLATE_DETAIL";
export const SALES_PAGES__UPDATE__FETCH_SECTION_DETAIL =
  "@SALES_PAGES/UPDATE/FETCH_SECTION_DETAIL";
export const SALES_PAGES__UPDATE__SET_SALE_PAGE_DETAILS =
  "@SALES_PAGES/UPDATE/SET_SALE_PAGE_DETAILS";
export const SALES_PAGES__UPDATE__SET_SECTION_DETAIL =
  "@SALES_PAGES/UPDATE/SET_SECTION_DETAIL";
export const SALES_PAGES__UPDATE__SET_SECTION_HAS_DATA =
  "@SALES_PAGES/UPDATE/SET_SECTION_HAS_DATA";
export const SALES_PAGES__UPDATE__SET_SELECTED_TAB =
  "@SALES_PAGES/UPDATE/SET_SELECTED_TAB";
export const SALES_PAGES_SET_VIDEO_UPLOADING =
  "@SALES_PAGES_SET_VIDEO_UPLOADING";

import { DEFAULT_TEMPLATE_UPDATE_TAB } from "../constants";
// eslint-disable-next-line no-unused-vars
import { ISalesPagesReducer } from "../types";
import {
  SALES_PAGES_SET_VIDEO_UPLOADING,
  SALES_PAGES__UPDATE_ALL_LIVE_SALES_PAGES_LIST,
  SALES_PAGES__UPDATE_SALES_PAGES_LISTS,
  SALES_PAGES__UPDATE_TEMPLATES_LIST,
  SALES_PAGES__UPDATE__SET_SALES_PAGE_DETAIL,
  SALES_PAGES__UPDATE__SET_SALE_PAGE_DETAILS,
  SALES_PAGES__UPDATE__SET_SECTION_DETAIL,
  SALES_PAGES__UPDATE__SET_SECTION_HAS_DATA,
  SALES_PAGES__UPDATE__SET_SELECTED_TAB,
  SALES_PAGES__UPDATE__SET_TEMPLATE_DETAIL,
} from "./actions";

const initialState: ISalesPagesReducer = {
  update: {
    title: "",
    listingUuid: "",
    selectedTemplate: {
      tabs: {},
      template_data: {},
    },
    listingData: {},
    selectedUpdateTemplateTab: DEFAULT_TEMPLATE_UPDATE_TAB,
  },
  templatesList: [],
  allLiveSalesPagesList: [],
  salesPagesLists: {},
  selectedSalesPage: {},
  videoUploadDetails: {},
};

const reducer = (
  state = initialState,
  // eslint-disable-next-line no-unused-vars
  { type, payload }: { type: string; payload: any }
) => {
  switch (type) {
    case SALES_PAGES__UPDATE__SET_TEMPLATE_DETAIL: {
      return {
        ...state,
        update: { ...state.update, selectedTemplate: payload },
      };
    }

    case SALES_PAGES__UPDATE__SET_SECTION_DETAIL: {
      const { selectedTab, sectionData, sectionUuid } = payload;
      let template = state.update.selectedTemplate;
      if (template.tabs) {
        let section = template.tabs[selectedTab].find(
          (item) => item.uuid === sectionUuid
        );
        if (section?.section_data)
          section = {
            ...section,
            section_data: Object.assign(section.section_data, sectionData),
          };
        else if (section) Object.assign(section, { section_data: sectionData });

        return {
          ...state,
          update: { ...state.update, selectedTemplate: template },
        };
      }
      return state;
    }

    case SALES_PAGES__UPDATE__SET_SECTION_HAS_DATA: {
      const { selectedTab, value, sectionUuid } = payload;
      let template = state.update.selectedTemplate;
      if (template.tabs) {
        let section = template.tabs[selectedTab].find(
          (item) => item.uuid === sectionUuid
        );
        if (section) {
          Object.assign(section, { has_section_data: value });
        }

        return {
          ...state,
          update: { ...state.update, selectedTemplate: { ...template } },
        };
      }
      return state;
    }

    case SALES_PAGES__UPDATE__SET_SELECTED_TAB: {
      return {
        ...state,
        update: { ...state.update, selectedUpdateTemplateTab: payload },
      };
    }

    case SALES_PAGES__UPDATE_TEMPLATES_LIST: {
      return {
        ...state,
        templatesList: payload,
      };
    }

    case SALES_PAGES__UPDATE_SALES_PAGES_LISTS: {
      return {
        ...state,
        salesPagesLists: { ...state.salesPagesLists, ...payload },
      };
    }

    case SALES_PAGES__UPDATE_ALL_LIVE_SALES_PAGES_LIST: {
      return {
        ...state,
        allLiveSalesPagesList: payload,
      };
    }

    case SALES_PAGES__UPDATE__SET_SALE_PAGE_DETAILS: {
      return {
        ...state,
        update: { ...state.update, ...payload },
      };
    }

    case SALES_PAGES__UPDATE__SET_SALES_PAGE_DETAIL: {
      return {
        ...state,
        selectedSalesPage: payload,
      };
    }
    case SALES_PAGES_SET_VIDEO_UPLOADING: {
      return {
        ...state,
        videoUploadDetails: {
          ...state,
          ...payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
